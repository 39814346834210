import React from "react";
import "./Footer.css";
import { ReactComponent as CopyrightIcon } from "../../../images/copyright.svg";

const Footer = () => {
    return (
        <div className="aboutUsFooterCopyrightContainer">
        <p className="aboutUsFooterCopyrightText">
          <CopyrightIcon className="aboutUsFooterCopyrightIcon"/> Mali Programer 2024
        </p>
      </div>
    );
}

export default Footer;
        