import { useTranslation } from "react-i18next";

export const GeneralSponsor = () => {
  const { t } = useTranslation();
  return (
    <div className="partners-section-container">
      <h1 className="partners-main-section-title">
        {t("generalSponsorTitle")}
      </h1>
      <a
        href="https://www.telemach.ba/"
        target="blank"
        className="general-sponsores-link"
      >
        <div className="general-sponsores-container">
          <img
            src="https://www.supernova-slavonskibrod.hr//fileadmin/shared/logos/Logo-Telemach-omjer-1_1.png"
            alt=""
            className="general-sponsor-logo"
          />
        </div>
      </a>
    </div>
  );
};
