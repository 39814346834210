import "./Input.css";
import React, { useState } from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as Icon } from "../../assets/icon.svg";
import { useTranslation } from "react-i18next";
import "../../../../../i18n";
import axios from "axios";

const Input = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    if (!firstName || !lastName || !company || !type) {
      setError(true);
      return;
    }
    setLoading(true);
    const response = await axios.post(
      "https://hack-day-backend.vercel.app/partnerRegister",
      {
        firstName,
        lastName,
        company,
        type,
        message,
      }
    );
    if (response.status === 200) {
      setFirstName("");
      setLastName("");
      setCompany("");
      setType("");
      setMessage("");
      setLoading(false);
      setError(false);
      alert(t("registerAlertSuccess"));
    }
  };

  return (
    <div className="input_container">
      <div className="input_content_container">
        <div className="input_description">
          <Logo className="input_description_logo" />
          <div className="input_description_text">
            <h1 className="input_description_text_title">
              {t("partnerRegisterTitle")}
            </h1>
            <p className="input_description_text_paragraph">
              {t("partnerRegisterTitleText")}
            </p>
          </div>
        </div>
        <div className="input_field">
          <div className="input_group">
            <div className="input">
              <label className="input_group_label">
                {t("registerInputLabelName")}
              </label>
              <input
                className="input_group_input"
                type="text"
                placeholder={t("registerInputPlaceholderName")}
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              <div className="input_group_error">
                {error ? <p>{t("registerInputErrorName")}</p> : null}
              </div>
            </div>
            <div className="input">
              <label className="input_group_label">
                {t("registerInputLabelSurname")}
              </label>
              <input
                className="input_group_input"
                type="text"
                placeholder={t("registerInputPlaceholderSurname")}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
              <div className="input_group_error">
                {error ? <p>{t("registerInputErrorSurname")}</p> : null}
              </div>
            </div>
          </div>
          <div className="input">
            <label className="input_group_label">
              {t("partnerRegisterInputLabelCompany")}
            </label>
            <input
              className="input_group_input"
              type="text"
              placeholder={t("partnerRegisterInputPlaceholderCompany")}
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            />
            <div className="input_group_error">
              {error ? <p>{t("partnerRegisterInputErrorCompany")}</p> : null}
            </div>
          </div>
          <div className="input">
            <label className="input_group_label">
              {t("partnerRegisterInputLabelType")}
            </label>
            <select
              className={
                type
                  ? "input_group_input"
                  : "input_select_placeholder input_group_input"
              }
              onChange={(e) => setType(e.target.value)}
              value={type}
            >
              <option value="" disabled selected className="input_select_placeholder">
                {t("partnerRegisterInputPlaceholderType")}
              </option>
              <option value="Partner">{t("partnerRegisterInputTypeOption1")}</option>
              <option value="Izlagač">{t("partnerRegisterInputTypeOption2")}</option>
              <option value="Volonter">{t("partnerRegisterInputTypeOption3")}</option>
            </select>
            <div className="input_group_error">
              {error ? <p>{t("partnerRegisterInputErrorType")}</p> : null}
            </div>
          </div>
          <div className="input">
            <label className="input_group_label">
              {t("partnerRegisterInputLabelMessage")}
            </label>
            <textarea
              className="input_group_input input_group_input_textarea"
              placeholder={t("partnerRegisterInputPlaceholderMessage")}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
          </div>
          <div className="input">
            <button
              className={
                loading ? "input_button_loading input_button" : "input_button"
              }
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <img
                  src={require("../../assets/spinner.gif")}
                  alt="loading"
                  className="input_button_spinner"
                />
              ) : (
                <>
                  <Icon className="input_button_icon" />
                  {t("registerInputSubmit")}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Input;
