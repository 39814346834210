import aboutKHDlogo from "../../../../assets/images/about-khd-logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "../../../../i18n";
const AboutKHD = () => {
  const { t } = useTranslation();
  return (
    <div className="about-khd">
      <img className="about-khd-logo" src={aboutKHDlogo} alt="" />
      <div className="about-khd-text-wrapper">
        <h1 className="about-khd-title">{t("whatiskhd")}</h1>
        <div className="about-khd-text">
          <p className="about-khd-bigtxt">
            <span className="underline-text">{t("underlinetext1")}</span>
            {t("aboutkhdtext1")}
          </p>
        </div>
      </div>
      <button
        onClick={() => {
          window.open("https://www.kidshackday.com/", "_blank");
        }}
        className="about-khd-button"
      >
        {t("findoutbtn")}
        <FontAwesomeIcon
          color="#FFF"
          className="about-khd-btn-icon"
          icon={faArrowUpRightFromSquare}
        />
      </button>
    </div>
  );
};

export default AboutKHD;
