import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./src/features/home";
import Register from "./src/features/register/Register";
import AboutUs from "./src/features/aboutUs/AboutUs";
import Partners from './src/features/partners';
import PartnerRegister from "./src/features/partnerRegister/PartnerRegister";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/onama" element={<AboutUs />} />
          <Route path="/register" element={<Register />} />
          <Route path="/partneri" element={<Partners/>} />
          <Route path="/partner-register" element={<PartnerRegister />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
