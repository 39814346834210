import Navbar from "../navbar";
import AboutUsFooter from "./AboutUsFooter";
import AboutUsGallery from "./AboutUsGallery";
import AboutUsHeader from "./AboutUsHeader";
import AboutUsInformation from "./AboutUsInformation";
import Footer from "../footer/Footer";
import "./aboutUs.css";

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <div className="aboutUsContainer">
        <AboutUsHeader />
        <AboutUsInformation />
        <AboutUsGallery />
        <AboutUsFooter />
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
