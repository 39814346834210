import { useTranslation } from "react-i18next";

const images = [
  {
    url: require("../../../assets/images/izlagaci/rsg-radio-logo.jpg"),
    alt: "rsg-logo",
    className: "partner-logo",
    web: "https://rsg.ba",
    isContained: true,
  },
  {
    url: require("../../../assets/images/izlagaci/travnicki-info-logo.png"),
    alt: "travnicki-info-logo",
    className: "partner-logo",
    web: "https://www.travnicki.info/",
  },
];
export const MediaPartnersSection = () => {
  const { t } = useTranslation();
  return (
    <div className="partners-section-container" style={{ paddingBottom: 50 }}>
      <h1 className="partners-main-section-title">
        {t("partnersMainSectionTitle4")}
      </h1>
      <div className="partners-section">
        {images.map((image) => {
          return (
            <a href={image.web} target="blank" key={image.url}>
              <div className="partners-logo-container">
                <img
                  className={image.className}
                  style={{
                    objectFit: image?.isContained ? "contain" : "cover",
                  }}
                  src={image.url}
                  alt={image.alt}
                ></img>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
