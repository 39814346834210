import { useTranslation } from "react-i18next";

const images = [
  {
    url: require("../../../assets/images/izlagaci/opcina-travnik-logo.jpg"),
    alt: "opcina-travnik-logo",
    className: "partner-logo",
    web: "https://opcinatravnik.com.ba/ba/",
  },
  {
    url: require("../../../assets/images/izlagaci/sbk-logo.jpeg"),
    alt: "sbk-logo",
    className: "partner-logo",
    web: "https://sbk-ksb.gov.ba/bs/",
  },
  {
    url: require("../../../assets/images/izlagaci/fmon.png"),
    alt: "fmon",
    className: "partner-logo-medium",
    web: "http://fmon.gov.ba/",
  },
  {
    url: require("../../../assets/images/izlagaci/francuska.png"),
    alt: "francuska ambasada",
    className: "partner-logo-medium",
    web: "https://ba.ambafrance.org/-Ambasada-",
  },
  {
    url: require("../../../assets/images/izlagaci/british.png"),
    alt: "british council",
    className: "partner-logo-medium",
    web: "https://www.britishcouncil.ba/en",
  },
  {
    url: require("../../../assets/images/izlagaci/turisticka-logo.jpeg"),
    alt: "turisticka-logo",
    isContained: true,
    className: "partner-logo",
  },
  {
    url: require("../../../assets/images/izlagaci/asabanka.png"),
    alt: "asa banka",
    className: "partner-logo-medium",
    web: "https://www.asabanka.ba/",
  },
  {
    url: require("../../../assets/images/izlagaci/vlasicgradnja.jpeg"),
    alt: "vlasic gradnja",
    className: "partner-logo-medium",
  },
  {
    url: require("../../../assets/images/izlagaci/klika.png"),
    alt: "klika",
    className: "partner-logo-medium",
    web: "https://www.klika.us/",
  },
  {
    url: require("../../../assets/images/izlagaci/gstmt.png"),
    alt: "gs tmt",
    className: "partner-logo-medium",
    web: "https://gs-tmt.com/",
  },
];
export const GovermentPartnersSection = () => {
  const { t } = useTranslation();
  return (
    <div className="partners-section-container">
      <h1 className="partners-main-section-title">
        {t("partnersMainSectionTitle3")}
      </h1>
      <div className="partners-section">
        {images.map((image) => {
          return (
            <a href={image.web} target="blank" key={image.url}>
              <div className="partners-logo-container">
                <img
                  className={image.className}
                  style={{
                    objectFit: image?.isContained ? "contain" : "cover",
                  }}
                  src={image.url}
                  alt={image.alt}
                ></img>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
