import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bottomImage from '../../../../assets/images/bottom-image.png';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';
const SponsorSection = () => {
	const { t } = useTranslation();
	return (
		<div className='sponsor-section'>
			<div className='sponsor-left-side'>
				<div className='sponsor-text-main'>
					<h1 className='sponsor-title'>{t('sponsorhelp')}</h1>
					<p className='sponsor-text'>{t('sponsortext1')}</p>
					<p className='sponsor-text'>{t('sponsortext2')}</p>
				</div>
				<button
					onClick={() => {
						window.location.href = '/partner-register';
					}}
					className='sponsor-button'>
					<FontAwesomeIcon
						color='#00B0F5'
						className=''
						icon={faEnvelope}
					/>
					{t('contactus')}
				</button>
			</div>
			<div className='sponsor-right-side'>
				<img className='bottom-img' src={bottomImage} />
			</div>
		</div>
	);
};

export default SponsorSection;
