import headerLogo from "../../../../assets/images/header-logo.png"
import { useTranslation } from "react-i18next";
import "../../../../i18n"
const VideoSection = () => {
    const { t } = useTranslation();
    return(
        <div className="video-section">
        <div className="video-section-header">
          <img className="video-section-logo" src={headerLogo} />
          <div className="video-section-text-wrapper">
            <p className="video-section-text">{t("videotext1")}</p>
            <p className="video-section-text">{t("videotext2")}</p>
          </div>
        </div>
        <div className="video-wrapper">
          <iframe
            width="95%"
            src="https://www.youtube.com/embed/RFxNejbL0Os?si=Iape98iSbgnN9mLS&controls=0"
            className="video"
            allowFullScreen
          />
        </div>
      </div>
    );
  };
  
  export default VideoSection;
  