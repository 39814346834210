import React from "react";
import Navbar from "../navbar";
import "./home.css";
import "./mobile.css";
import AboutKHD from "./about-khd";
import VideoSection from "./video-section";
import MapSection from "./map-section";
import SponsorSection from "./sponsor-section";
import Header from "./header";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <div className="home-container">
        <Header/>
        <AboutKHD />
        <VideoSection />
        <MapSection />
        <SponsorSection />
        <div className="footer">
          <p className="footer-text">{t("footer")}</p>
        </div>
      </div>
    </>
  );
};

export default Home;
