import React from "react";
import video from "../../../../assets/videos/khd-video.mp4";
import headerLogo from "../../../../assets/images/header-logo.png";
import { useTranslation } from "react-i18next";
import "../../../../i18n";

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="fullscreen-video-container">
      <video
        className="fullscreen-video"
        muted
        loop
        autoPlay
        playsInline={true}
        disablePictureInPicture={true}
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay"></div>
      <div className="home-header-text-container">
        <img className="home-header-logo" src={headerLogo} alt="Header Logo" />
        <h1 className="home-header-text">{t("date")}</h1>
      </div>
    </div>
  );
};

export default Header;
