import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "bos",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    bos: {
      translation: {
        naslovna: "NASLOVNA",
        onama: "O NAMA",
        partneri: "PARTNERI",
        faq: "FAQ",
        prijavise: "PRIJAVI SE",
        date: "15 JUNI 10:00 - 18:00, GRADSKI TRG TRAVNIK",
        footer: "© Mali Programer 2024",
        whatiskhd: "Šta je Kids Hack Day?",
        underlinetext1: "Kids Hack Day",
        underlinetext2: "Mali Programer",
        aboutkhdtext1:
          " je globalni event, koji je zahvaljujući školi programiranja i robotike – Mali Programer, doveden u Bosnu i Hercegovinu. U danima održavanja Kids Hack Day-a, potičemo naše najmlađe da kroz seriju zanimljivih izazova dođu do rješenja iz oblasti robotike i programiranja. KHD se istovremeno održava u nekoliko metropola na više kontinenata",
        aboutkhdtext2: "",
        findoutbtn: "Saznaj više ",
        whereandwhen: "Kad i gdje?",
        mapdate: "15. Juni 2024",
        maptime: " od 10:00 do 18:00",
        maplocation1: "Gradski trg Travnik",
        maplocation2: "Bosanska 135, 72270 Travnik",
        maptext1:
          "Za peto izdanje Travničkog Kids Hack Day-a spremamo nešto posebno! Očekujemo da će nam se pridružiti više stotina mladih entuzijasta spremnih za nezaboravne izazove koji će obilježiti ovu manifestaciju. Ovo će biti prilika da zajedno stvorimo čarobne trenutke i pošaljemo prekrasnu sliku iz srca Bosne i Hercegovine.",
        maptext2:
          "Naš cilj je da postanemo dio istorije Travnika kao pioniri koji su okupili ogroman broj kreativnih mladih ljudi, te poslali snažnu poruku zajedništva i napretka društva u cjelini.",
        maptext3:
          "Naglašavamo da će organizaciju eventa preuzeti kompanija Priredba iz Sarajeva, a program će voditi poznati bh voditelj Adi Bebanić. Također, s ponosom ističemo da će u trci robota sudjelovati preko 40 ekipa, dodatno obogaćujući doživljaj festivala.",
        registerbtn: "Registruj se",
        sponsorhelp: "Želite nam pomoći?",
        sponsortext1:
          "Zajedno kreiramo historiju i stavljamo Travnik i BiH na mapu svijeta. Naše svjetlo na globalnoj karti može još jače zasjati ako nam se pridružiš kao partner, izlagač ili učesnik! Prijavi se sada!",
        sponsortext2: "Kontaktirajte nas danas i pridružite se našem timu!",
        contactus: "Kontaktirajte nas",
        videotext1:
          "Svjesni činjenice da veliki dio kvalitete jednog dešavanja možemo predstaviti prenošenjem atmosfere s istog, pogledajte naš video i uvjerite se koliko se svaki put zabavljamo, ali istovremeno učimo i razvijamo se!",
        videotext2:
          "Pogledajte video ispod s jednog od naših prethodnih događaja!",
        questionsTitle: "Često postavljana pitanja",
        questionsSubtitleChildren: "Učesnici",
        questionsChildrenPoint1: "Starost:",
        questionsChildrenText1: "6+ godina",
        questionsChildrenPoint2: "Broj učesnika:",
        questionsChildrenText2: "Up to 200",
        questionsChildrenPoint3: "Instruktori:",
        questionsChildrenText3: "1 na 5-10 djece",
        questionsChildrenPoint4: "Cijena:",
        questionsChildrenText4: "BESPLATNO!",
        questionsChildrenPoint5: "Ručak i grickalice:",
        questionsChildrenText5: "Obezbjeđuje organizator",
        questionsChildrenSubtext:
          "Molimo vas da nas obavijestite ako sudionik ima posebne prehrambene potrebe.",
        questionsPlaceTitle: "Ključne Lokacije",
        questionsPlaceSubtitle1: "Stanica 1:",
        questionsPlaceText1: "Dobrodošlica i registracija",
        questionsPlaceSubtitle2: "Stanica 2:",
        questionsPlaceText2: "Izgradnja Lego Travnik 2035 makete",
        questionsPlaceSubtitle3: "Stanica 3:",
        questionsPlaceText3: "STEM aktivnosti",
        questionsPlaceSubtitle4: "Stanica 4:",
        questionsPlaceText4: "Hackathon za djecu",
        questionsPapersTitle: "Uslovi i pravila učešća",
        questionsPapersPoint1: "1.",
        questionsPapersText1:
          "Budući da je ovo besplatan događaj za zajednicu, svaki učesnik se mora prijaviti sam preko svojih roditelja ili staratelja.",
        questionsPapersPoint2: "2.",
        questionsPapersText2: "Jedna registracija odgovara jednom učesniku.",
        questionsPapersPoint3: "3.",
        questionsPapersText3:
          "Učesnici su podložni konačnoj verifikaciji i potvrdi.",
        questionsPapersPoint4: "4.",
        questionsPapersText4:
          "Velike grupe (npr. iz iste škole) trebaju nas prvo kontaktirati prije registracije i podložne su našim uslovima i odredbama.",
        questionsDropdownTitle: "Što je kids hackday?",
        questionsDropdownText:
          "Kids Hack Day je globalna serija besplatnih kreativnih tehnoloških događaja za djecu. Za više informacija posjetite zvaničnu web stranicu Kids Hack Day.",
        aboutUsFooterTitle: "Želite nam pomoći?",
        aboutUsFooterFirstText:
          "Pridružite nam se u inspirisanju naredne generacije mladih umova na našem Kids Hack Day-u! Tražimo strastvene govornike i instruktore koji bi željeli podijeliti svoje znanje i ostaviti trajni uticaj na budućnost ovih mladih ljudi.",
        aboutUsFooterSecondText:
          "Kontaktirajte nas danas i pridružite se našem timu!",
        aboutUsFooterLink: "Kontaktirajte nas",
        aboutUsTitle: "O Nama:",
        aboutUsSchool: "Škola programiranja i robotike ",
        aboutUsSchoolName: "“Mali Programer”",
        aboutUsSchoolNameText: "Mali programer",
        aboutUsInformationText1:
          " nastala je kao potreba prepoznata od strane osnivača za ovim vidom",
        aboutUsInformationText2:
          " neformalnog obrazovanja i usmjeravanja djece.",
        aboutUsInformationText3: "Činjenica je da je IT branša trenutno",
        aboutUsInformationText4: " najatraktivnije globalno zanimanje",
        aboutUsInformationText5: ", a ukoliko se to",
        aboutUsInformationText6: " usmjeravanje kroz igru",
        aboutUsInformationText7:
          " praktikuje od mladosti, rezultati su efektivniji.",
        aboutUsInformationText8:
          "Pored svakodnevnih časova u prostorijama naše Škole, nerijetko izlazimo u susret formalnim obrazovnim ustanovama, gdje vršimo edukacije, a također smo u naš grad donijeli i globalni event - ",
        aboutUsInformationText9: "Kids Hack Day",
        aboutUsInformationText10:
          "Rad Malog programera od početka škole podržava Općina Travnik, predvođena načelnikom općine gospodinom ",
        aboutUsInformationText11: "Kenanom Dautovićem",
        aboutUsInformationText12:
          ". Kroz naš zajednički rad, nastojimo mladima omogućiti pristup tehnologiji, upoznati ih s najnovijim trendovima u programiranju i robotici te na zabavan i interesantan način zajedno učiti i oblikovati bolju zajednicu.",
        aboutUsInformationLink: "Saznaj više",
        partnersTitle: "Partneri događaja Kids Hack Day",
        partnersMainSectionTitle1: "Organizatori",
        partnersMainSectionTitle2: "Izlagači",
        partnersMainSectionTitle3: "Partneri projekta",
        partnersMainSectionTitle4: "Medijski partneri",
        partnerhelp: "Želite biti partner?",
        dropdownTitle1:
          "Postoje li uslovi za identifikaciju ili minimalnu dob kako bi se moglo učestvovati?",
        dropdownText1: "Minimalna dob učesnika je 6 godina.",
        dropdownTitle2: "Šta je Kids Hack Day?",
        dropdownText2:
          "Kids Hack Day je globalna serija besplatnih kreativnih tehnoloških događaja za djecu. Za više informacija posjetite zvaničnu web stranicu Kids Hack Day.",
        dropdownTitle3:
          "Postoji li ograničenje u vezi s brojem ulaznica koje mogu dobiti?",
        dropdownText3:
          "Da, dvije ulaznice po porodici. Morate se registrovati dva puta da biste dobili dvije ulaznice.",
        dropdownTitle4: "Mogu li se prijaviti u ime svog prijatelja?",
        dropdownText4:
          "Ne, svaki učesnik se mora sam prijaviti preko svojih roditelja.",
        dropdownTitle5:
          "Treba li nešto ponijeti ili platiti neki iznos da bih učestvovao/la?",
        dropdownText5: "Sve materijale ćemo obezbijediti, uključujući hranu.",
        dropdownTitle6:
          "Bio/bila sam na vašem događaju prije. Mogu li ponovno učestvovati?",
        dropdownText6:
          "Da, ukoliko bude dovoljno mjesta. Broj mjesta je ograničen i moramo biti obazrivi prema drugima koji žele da učestvuju.",
        dropdownTitle7:
          "Registrirao/la sam se, ali ne mogu prisustvovati. Mogu li otkazati učešće?",
        dropdownText7:
          "Da. Molimo vas da to učinite putem svog računa na našoj web stranici događaja i označite svoje prisustvo kao nedolazak. Ili nam se možete direktno obratiti putem e-pošte.",
        registerTitle: "Želiš učestvovati?",
        registerTitleText:
          "Popuni ovaj kratki formular i kontaktirat ćemo te u najkraćem roku. Spremi se da postaneš dio ove fenomenalne priče!",
        registerInputLabelName: "Ime",
        registerInputPlaceholderName: "Upiši ime...",
        registerInputLabelSurname: "Prezime",
        registerInputPlaceholderSurname: "Upiši prezime...",
        registerInputLabelBirth: "Godište",
        registerInputPlaceholderBirth: "Odaberi godište...",
        registerInputLabelGender: "Spol",
        registerInputPlaceholderGender: "Odaberi spol...",
        registerInputGenderOption1: "Muško",
        registerInputGenderOption2: "Žensko",
        registerInputLabelCity: "Grad iz kojeg dolaziš",
        registerInputPlaceholderCity: "Upiši grad...",
        registerInputLabelSchool: "Ime škole iz koje dolaziš",
        registerInputPlaceholderSchool: "Upiši ime škole...",
        registerInputSubmit: "Pošalji aplikaciju",
        registerAlertSuccess: "Uspješno ste se prijavili!",
        registerAlert2: "Molimo popunite sva polja!",
        partnerRegisterTitle: "Želiš nam pomoći u realizaciji eventa?",
        partnerRegisterTitleText:
          "Popuni ovaj kratki formular i kontaktirat ćemo te u najkraćem roku. Spremi se da postaneš dio ove fenomenalne priče!",
        partnerRegisterInputLabelCompany: "Kompanija ili organizacija",
        partnerRegisterInputPlaceholderCompany:
          "Upiši ime kompanije ili organizacije...",
        partnerRegisterInputLabelType: "Način učešća",
        partnerRegisterInputPlaceholderType: "Odaberi način učešća...",
        partnerRegisterInputTypeOption1: "Partner",
        registerInputErrorGender: "Molimo odaberite spol!",
        registerInputErrorCity: "Molimo unesite grad!",
        registerInputErrorSchool: "Molimo unesite ime škole!",
        partnerRegisterInputTypeOption2: "Izlagač",
        partnerRegisterInputTypeOption3: "Volonter",
        partnerRegisterInputErrorCompany:
          "Molimo unesite ime kompanije ili organizacije!",
        partnerRegisterInputErrorType: "Molimo odaberite način učešća!",
        partnerRegisterInputLabelMessage:
          "Poruka za organizatora (nije obavezno)",
        partnerRegisterInputPlaceholderMessage: "Upiši poruku...",
        generalSponsorTitle: "Generalni sponzor",
        registerInputErrorName: "Molimo unesite ime!",
        registerInputErrorSurname: "Molimo unesite prezime!",
        registerInputErrorBirth: "Molimo odaberite godište!",
      },
    },
  },
});
export default i18n;
