import './aboutUs.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as KHDMascot } from '../../../images/khdMascot.svg';
import { ReactComponent as MailIcon } from '../../../images/mail.svg';

const AboutUsFooter = () => {
	const { t } = useTranslation();
	return (
		<>
			<div className='aboutUsFooterContainer'>
				<div className='aboutUsFooterText'>
					<h1 className='aboutUsFooterTitle'>
						{t('aboutUsFooterTitle')}
					</h1>
					<p className='aboutUsFooterParagraph'>
						{t('aboutUsFooterFirstText')}
					</p>
					<p className='aboutUsFooterParagraph'>
						{t('aboutUsFooterSecondText')}
					</p>
					<button
						onClick={() => {
							window.location.href = '/partner-register';
						}}
						className='aboutUsFooterButton'>
						<MailIcon />
						<span className='aboutUsFooterButtonText'>
							{t('aboutUsFooterLink')}
						</span>
					</button>
				</div>
				<KHDMascot className='aboutUsFooterImage' />
			</div>
		</>
	);
};

export default AboutUsFooter;
