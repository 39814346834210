import "./Input.css";
import React, { useState } from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as Icon } from "../../assets/icon.svg";
import { useTranslation } from "react-i18next";
import "../../../../../i18n";
import axios from "axios";

const Input = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [birth, setBirth] = useState("");
  const [city, setCity] = useState("");
  const [school, setSchool] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    if (!firstName || !lastName || !gender || !birth || !city || !school) {
      setError(true);
      return;
    }
    setLoading(true);
    const response = await axios.post(
      "https://hack-day-backend.vercel.app/sendEmail",
      {
        firstName,
        lastName,
        gender,
        birthYear: birth,
        city,
        school,
      }
    );
    if (response.status === 200) {
      setFirstName("");
      setLastName("");
      setGender("");
      setBirth("");
      setCity("");
      setSchool("");
      setLoading(false);
      setError(false);
      alert(t("registerAlertSuccess"));
    }
  };

  return (
    <div className="input_container">
      <div className="input_content_container">
        <div className="input_description">
          <Logo className="input_description_logo" />
          <div className="input_description_text">
            <h1 className="input_description_text_title">
              {t("registerTitle")}
            </h1>
            <p className="input_description_text_paragraph">
              {t("registerTitleText")}
            </p>
          </div>
        </div>
        <div className="input_field">
          <div className="input_group">
            <div className="input">
              <label className="input_group_label">
                {t("registerInputLabelName")}
              </label>
              <input
                className="input_group_input"
                type="text"
                placeholder={t("registerInputPlaceholderName")}
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              <div className="input_group_error">
                {error ? <p>{t("registerInputErrorName")}</p> : null}
              </div>
            </div>
            <div className="input">
              <label className="input_group_label">
                {t("registerInputLabelSurname")}
              </label>
              <input
                className="input_group_input"
                type="text"
                placeholder={t("registerInputPlaceholderSurname")}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
              <div className="input_group_error">
                {error ? <p>{t("registerInputErrorSurname")}</p> : null}
              </div>
            </div>
          </div>
          <div className="input_group">
            <div className="input">
              <label className="input_group_label">
                {t("registerInputLabelBirth")}
              </label>
              <select
                className={
                  birth
                    ? "input_group_input"
                    : "input_select_placeholder input_group_input"
                }
                onChange={(e) => setBirth(e.target.value)}
                value={birth}
              >
                <option
                  disabled
                  selected
                  value=""
                  className="input_select_placeholder"
                >
                  {t("registerInputPlaceholderBirth")}
                </option>
                {Array.from(Array(125).keys()).map((_, i) => {
                  const year = 2024 - i;
                  return <option value={year}>{year}</option>;
                })}
              </select>
              <div className="input_group_error">
                {error ? <p>{t("registerInputErrorBirth")}</p> : null}
              </div>
            </div>
            <div className="input">
              <label className="input_group_label">
                {t("registerInputLabelGender")}
              </label>
              <select
                className={
                  gender
                    ? "input_group_input"
                    : "input_select_placeholder input_group_input"
                }
                onChange={(e) => setGender(e.target.value)}
                value={gender}
              >
                <option
                  disabled
                  selected
                  value=""
                  className="input_select_placeholder"
                >
                  {t("registerInputPlaceholderGender")}
                </option>
                <option value="Muško">{t("registerInputGenderOption1")}</option>
                <option value="Žensko">
                  {t("registerInputGenderOption2")}
                </option>
              </select>
              <div className="input_group_error">
                {error ? <p>{t("registerInputErrorGender")}</p> : null}
              </div>
            </div>
          </div>
          <div className="input">
            <label className="input_group_label">
              {t("registerInputLabelCity")}
            </label>
            <input
              className="input_group_input"
              type="text"
              placeholder={t("registerInputPlaceholderCity")}
              onChange={(e) => setCity(e.target.value)}
              value={city}
            />
            <div className="input_group_error">
              {error ? <p>{t("registerInputErrorCity")}</p> : null}
            </div>
          </div>
          <div className="input">
            <label className="input_group_label">
              {t("registerInputLabelSchool")}
            </label>
            <input
              className="input_group_input"
              type="text"
              placeholder={t("registerInputPlaceholderSchool")}
              onChange={(e) => setSchool(e.target.value)}
              value={school}
            />
            <div className="input_group_error">
              {error ? <p>{t("registerInputErrorSchool")}</p> : null}
            </div>
          </div>
          <div className="input">
            <button
              className={
                loading ? "input_button_loading input_button" : "input_button"
              }
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <img
                  src={require("../../assets/spinner.gif")}
                  alt="loading"
                  className="input_button_spinner"
                />
              ) : (
                <>
                  <Icon className="input_button_icon" />
                  {t("registerInputSubmit")}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Input;
