import "./PartnerRegister.css";
import Input from "./components/Input/Input";
import Navbar from "../navbar/index";
import Footer from "../footer/Footer";

const PartnerRegister = () => {
  return (
    <div className="register">
      <Navbar />
      <Input />
      <Footer />
    </div>
  );
};

export default PartnerRegister;
