import Navbar from "../navbar";
import "./partners.css";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import HeaderImage from "../../../assets/images/partners-header-image.png";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bottomImage from "../../../assets/images/bottom-image.png";
import Footer from "../footer/Footer";
import { PartnersSection } from "./PartnersSection";
import { GeneralSponsor } from "./GeneralSponsor";
import { MediaPartnersSection } from "./MediaPartners";
import { GovermentPartnersSection } from "./GovermentPartners";

const Partners = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="partners-header">
        <h1 className="partners-header-text">{t("partnersTitle")}</h1>
        <img className="header-image" src={HeaderImage} alt="header" />
      </div>
      <div className="partners-main-section">
        <GeneralSponsor />
        <GovermentPartnersSection />
        <PartnersSection />
        <MediaPartnersSection />
      </div>
      <div className="sponsor-section">
        <div className="sponsor-left-side">
          <div className="sponsor-text-main">
            <h1 className="sponsor-title">{t("partnerhelp")}</h1>
            <p className="sponsor-text">{t("sponsortext1")}</p>
            <p className="sponsor-text">{t("sponsortext2")}</p>
          </div>
          <button
            onClick={() => {
              window.location.href = "/partner-register";
            }}
            className="sponsor-button"
          >
            <FontAwesomeIcon color="#00B0F5" className="" icon={faEnvelope} />
            {t("contactus")}
          </button>
        </div>
        <div className="sponsor-right-side">
          <img className="bottom-img" src={bottomImage} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Partners;
