import { faClock, faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import locationDot from "../../../../assets/images/location-dot.png";
import mapImage from "../../../../assets/images/map.png";
import { useTranslation } from "react-i18next";
import "../../../../i18n";
const MapSection = () => {
  const { t } = useTranslation();

  return (
    <div className="map-section">
      <h1 className="map-title">{t("whereandwhen")}</h1>
      <div className="map-section-main">
        <div className="map-text">
          <div className="map-text-small">
            <h1 className="map-boldtext">
              <FontAwesomeIcon color="#ED0059" className="" icon={faCalendar} />{" "}
              <span className="gap">{t("mapdate")}</span>
            </h1>
            <h1 className="map-boldtext drugi-text">
              <FontAwesomeIcon color="#ED0059" className="" icon={faClock} />{" "}
              <span className="gap gap2">{t("maptime")}</span>
            </h1>

            <div className="map-location-wrapper">
              <img className="locationDot" src={locationDot} alt="" />
              <div className="gap">
                <h1 className="map-boldtext">{t("maplocation1")}</h1>
                <p className="map-text2">{t("maplocation2")}</p>
              </div>
            </div>
          </div>
          <div className="map-bigtext-wrapper">
            <p className="map-bigtext">{t("maptext1")}</p>
            <p className="map-bigtext">{t("maptext2")}</p>
            <p className="map-bigtext">
              Da bi organizacija eventa bila na visokom nivou zadužena je
              kompanija{" "}
              <a href="https://priredba.ba" target="_blank" rel="noreferrer">
                <span className="aboutUsInformationTextLink"> Priredba </span>
              </a>
              iz Sarajeva, a program će voditi poznati bh voditelj{" "}
              <a
                href="https://www.instagram.com/adibebanic/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="aboutUsInformationTextLink">Adi Bebanić</span>.
              </a>
              {""}
              Također, s ponosom ističemo da će u trci robota sudjelovati preko
              40 ekipa, dodatno obogaćujući doživljaj festivala.
            </p>
          </div>
          <button
            className="map-button"
            onClick={() => {
              window.location.href = "/register";
            }}
          >
            <FontAwesomeIcon color="#fff" icon={faCircleCheck} className="" />{" "}
            {t("registerbtn")}
          </button>
        </div>
        <div className="map-right-side">
          <div className="map-wrapper">
            <img className="map-image" src={mapImage} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapSection;
