import { useTranslation } from "react-i18next";

const images = [
  {
    url: require("../../../assets/images/izlagaci/292326564_419196630221774_260347351541452072_n.png"),
    alt: "292326564_419196630221774_260347351541452072_n",
    className: "partner-logo",
    web: "https://www.dkr.ba/",
  },
  {
    url: require("../../../assets/images/izlagaci/341679654_1606283266534500_2681464229640802510_n.jpg"),
    alt: "341679654_1606283266534500_2681464229640802510_n",
    className: "partner-logo",
    web: "https://www.facebook.com/maihanbooks",
  },
  {
    url: "https://schoolandschool.com/wp-content/uploads/2021/08/scool_logo_310.png",
    alt: "354443955_655157183298173_4318463065812759724_n",
    isContained: true,
    className: "partner-logo",
    web: " https://schoolandschool.com/",
  },
  {
    url: require("../../../assets/images/izlagaci/Fab_Lab_logo.png"),
    alt: "74687706_100341451406464_340871260563046400_n",
    className: "partner-logo-small",
    web: " https://fablab.ba/",
  },
  {
    url: require("../../../assets/images/izlagaci/Connect arena.jpg"),
    alt: "Connect arena",
    className: "partner-logo",
    web: "https://www.facebook.com/connectarenasarajevo/",
  },
  {
    url: require("../../../assets/images/izlagaci/FlexInvest Logo.jpg"),
    alt: "FlexInvest Logo",
    className: "partner-logo",
    web: "https://flexinvestdoo.com/",
  },
  {
    url: require("../../../assets/images/izlagaci/imtec-logo.jpeg"),
    alt: "Imtec",
    className: "partner-logo",
    web: "https://imtec.ba/",
  },
  {
    url: require("../../../assets/images/izlagaci/LEGO_logo.svg.png"),
    alt: "LEGO_logo.svg",
    className: "partner-logo",
    web: "https://svijetkockica.ba/",
  },
  {
    url: require("../../../assets/images/izlagaci/Picture1.png"),
    alt: "Picture1",
    isContained: true,
    className: "partner-logo",
    web: "https://iu-travnik.com/",
  },
  {
    url: require("../../../assets/images/izlagaci/didakta kids.jpg"),
    alt: "didakta kids",
    className: "partner-logo",
    web: "https://www.instagram.com/didakta_kids/",
  },
  {
    url: require("../../../assets/images/izlagaci/pecat u prostoru.png"),
    alt: "pecat u prostoru",
    isContained: true,
    className: "partner-logo-medium",
  },
  {
    url: require("../../../assets/images/izlagaci/wmta.png"),
    alt: "wmta",
    className: "partner-logo-medium",
    web: "https://wmta-edu.com/",
  },
  {
    url: require("../../../assets/images/izlagaci/microbit.jpg"),
    alt: "microbit",
    isContained: true,
    className: "partner-logo-medium",
    web: "https://microbit.org/",
  },
  {
    url: require("../../../assets/images/izlagaci/DIG-INN-3D-WMTA.webp"),
    alt: "dig inn 3d wmta",
    className: "partner-logo-medium",
    web: "https://wmta-edu.com/tag/dig-inn-3d-hub/",
  },
];
export const PartnersSection = () => {
  const { t } = useTranslation();
  return (
    <div className="partners-section-container">
      <h1 className="partners-main-section-title">
        {t("partnersMainSectionTitle2")}
      </h1>
      <div className="partners-section">
        {images.map((image) => {
          return (
            <a href={image.web} target="blank" key={image.url}>
              <div className="partners-logo-container">
                <img
                  className={image.className}
                  style={{
                    objectFit: image?.isContained ? "contain" : "cover",
                  }}
                  src={image.url}
                  alt={image.alt}
                ></img>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
