import React, { useState } from "react";
import "./navbar.css";
import navbarLogo from "../../../assets/images/navbar-logo.png";
import { useTranslation } from "react-i18next";
import "../../../i18n";
const Navbar = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = window.location;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };
    return (
        <div className={`navbar-container ${isMenuOpen ? 'menu-open' : ''}`}>
            <img className="navbar-logo" src={navbarLogo} alt="Navbar Logo" />
            <div className={`navbar-options-wrapper ${isMenuOpen ? 'menu-open' : ''}`}>
                <h1 className={`navbar-options ${location.pathname === "/" ? 'selected' : ''}`} onClick={
                    () => {
                        closeMenu();
                        window.location.href = "/";
                    }
                }>{t("naslovna")}</h1>
                <h1 className={`navbar-options ${location.pathname === "/onama" ? 'selected' : ''}`} onClick={
                    () => {
                        closeMenu();
                        window.location.href = "/onama";
                    }
                }>{t("onama")}</h1>
                <h1 className={`navbar-options ${location.pathname === "/partneri" ? 'selected' : ''}`}onClick={
                    () => {
                        closeMenu();
                        window.location.href = "/partneri";
                    }
                }>{t("partneri")}</h1>
                <h1 className={`navbar-options ${location.pathname === "/register" ? 'selected' : ''}`} onClick={
                    () => {
                        closeMenu();
                        window.location.href = "/register";
                    }
                }>{t("prijavise")}</h1>
            </div>
            <div className="hamburger-icon" onClick={toggleMenu}>
                <div className={`bar ${isMenuOpen ? 'bar-open' : ''}`}></div>
                <div className={`bar ${isMenuOpen ? 'bar-open' : ''}`}></div>
                <div className={`bar ${isMenuOpen ? 'bar-open' : ''}`}></div>
            </div>
        </div>
    );
};

export default Navbar;
