import { useTranslation } from "react-i18next";
import { ReactComponent as CopyIcon } from "../../../images/copy.svg";
import { ReactComponent as MaliProgramerLogo } from "../../../images/maliProgramer.svg";
import { useNavigate } from "react-router-dom";

const AboutUsInformation = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="aboutUsInformationContainer">
      <div className="aboutUsInformationText">
        <p className="aboutUsInformationParagraph">
          <span className="aboutUsInformationTextBolded">
            Dobrodošli na Kids Hack Day{" "}
          </span>{" "}
          - jedinstveni događaj koji spaja zabavu i učenje na nezaboravan način!{" "}
          <a
            href="https://www.opcinatravnik.com.ba/ba/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="aboutUsInformationTextLink">Općina Travnik</span>
          </a>{" "}
          je svojom kontinuiranom podrškom školi programiranja i robotike “Mali
          programer” već pokazala svoju opredijeljenost ulaganju u obrazovanje
          mladih u naprednim informatičkim vještinama. <br></br>{" "}
          <a href="https://maliprogramer.ba/" target="_blank" rel="noreferrer">
            <span className="aboutUsInformationTextLink">Mali programer</span>
          </a>{" "}
          u prethodna četiri izdanja Kids Hack Day-a, kao i svakodnevnu nastavu
          iz nekoliko STEM vještina, pokazao je tendenciju rasta i masovnosti.
          Od samog dolaska KHD-a u Travnik, do proteklog izdanja, napravljen je
          veliki organizacijski iskorak. Ovi partneri sada žele građanima
          Travnika, Bosne i Hercegovine, ali i regiona, priuštiti nezaboravno
          iskustvo koje će okupiti više od hiljadu djece na Travničkom korzu -
          glavnoj ulici našeg grada.
        </p>
      </div>
      <MaliProgramerLogo className="aboutUsInformationImage" />
    </div>
  );
};

export default AboutUsInformation;
