import "./aboutUs.css";

const images = [
  {
    url: require("../../../assets/images/gallery-1.jpg"),
    alt: "gallery-1",
  },
  {
    url: require("../../../assets/images/gallery-2.jpg"),
    alt: "gallery-2",
  },
  {
    url: require("../../../assets/images/gallery-3.jpg"),
    alt: "gallery-3",
  },
  {
    url: require("../../../assets/images/gallery-4.jpg"),
    alt: "gallery-4",
  },
  {
    url: require("../../../assets/images/gallery-5.jpg"),
    alt: "gallery-5",
  },
  {
    url: require("../../../assets/images/gallery-6.jpg"),
    alt: "gallery-6",
  },
];
const AboutUsGallery = () => {
  return (
    <div className="aboutUsGalleryContainer">
      {images.map((image) => {
        return (
          <div className="aboutUsGalleryImageContainer" key={image.url}>
            <img
              src={image.url}
              alt={image.alt}
              className="aboutUsGalleryImage"
            />
          </div>
        );
      })}
    </div>
  );
};

export default AboutUsGallery;
