  import "./Register.css";
import Input from "./components/Input/Input";
import Navbar from "../navbar/index";
import Footer from "../footer/Footer";

const Register = () => {
  return (
    <div className="register">
        <Navbar />
        <Input />
        <Footer />
    </div>
  );
};

export default Register;
