import React from "react";
import { useTranslation } from "react-i18next";

const AboutUsHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="aboutUsHeaderContainer">
      <div className="aboutUsHeaderText">
        <h1 className="aboutUsHeaderTitle">{t("aboutUsTitle")}</h1>
        <h2 className="aboutUsHeaderSubtitle">{t("aboutUsSchool")}</h2>
        <h2 className="aboutUsHeaderSubtitle">{t("aboutUsSchoolName")}</h2>
      </div>
      <img
        src={require("../../../images/kidsImage.png")}
        alt="kids playing"
        className="aboutUsHeaderImage"
      />
      <div className="aboutUsHeaderGradient"></div>
    </div>
  );
};

export default AboutUsHeader;
